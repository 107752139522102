var icons = [
  "meiye-routeicon-work-copy",
  "meiye-routeicon-3column",
  "meiye-routeicon-adjust",
  "meiye-routeicon-ashbin",
  "meiye-routeicon-back",
  "meiye-routeicon-caps-lock",
  "meiye-routeicon-chart-bar",
  "meiye-routeicon-attachment",
  "meiye-routeicon-check-item",
  "meiye-routeicon-calendar",
  "meiye-routeicon-comment",
  "meiye-routeicon-column-vertical",
  "meiye-routeicon-column-horizontal",
  "meiye-routeicon-complete",
  "meiye-routeicon-chart-pie",
  "meiye-routeicon-copy",
  "meiye-routeicon-cut",
  "meiye-routeicon-data-view",
  "meiye-routeicon-discount",
  "meiye-routeicon-electronics",
  "meiye-routeicon-drag",
  "meiye-routeicon-explain",
  "meiye-routeicon-file-common",
  "meiye-routeicon-file-delete",
  "meiye-routeicon-film",
  "meiye-routeicon-file",
  "meiye-routeicon-folder-close",
  "meiye-routeicon-image-text",
  "meiye-routeicon-home",
  "meiye-routeicon-keyboard-26",
  "meiye-routeicon-layout",
  "meiye-routeicon-layers",
  "meiye-routeicon-menu",
  "meiye-routeicon-modular",
  "meiye-routeicon-run-up",
  "meiye-routeicon-setting",
  "meiye-routeicon-toggle-left",
  "meiye-routeicon-toggle-right",
  "meiye-routeicon-user",
  "meiye-routeicon-work",
  "meiye-routeicon-add",
  "meiye-routeicon-add-circle"
]
export default icons