<template>
  <a-popover
    :trigger="trigger"
    v-model="visible"
  >
    <template #title>
      <a-input-search
        v-model="searchValue"
        placeholder="输入英文关键词进行搜索"
        @change="filterIcon"
      />
    </template>

    <template #content>
      <div class="icon-box">
        <div
          v-for="(item,index) in iconArr"
          :key="index"
          @click="handleClick(item)"
          class="icon-content"
          :class="icon === item ?'primarycolor':''"
        >
          <!-- <a-icon :type="item" /> -->
          <i
            class="meiye-icon"
            :class="item"
          ></i>
        </div>
      </div>
    </template>
    <slot name="iconSelect"></slot>
  </a-popover>
</template>

<script> 
import icons from "./modules/icon2";
export default {
  name: "IconPicker",
  props: {
    icon: {
      type: String,
      default: null,
    },
    //自定义触发方式
    trigger: {
      type: String,
      default: "click",
    },
  },
  data() {
    return {
      iconArr: icons,
      visible: false,
      searchValue: null,

    }
  },
  methods: {
    handleClick(icon) {
      console.log(icon, 'icon');
      this.$emit('getIconName', icon)
      this.visible = false;
    },
    filterIcon() {
      //  
      if (this.searchValue) {
        this.iconArr = icons.filter((item) =>
          item.toLowerCase().includes(this.searchValue.toLowerCase())
        );
        // console.log( this.iconArr, 'aaa');
      }
    },
  },
  //   watch(visible, () => {
  //     searchValue.value = "";
  //     iconArr.value = icons;
  //   });

  // setup(props, context) {
  //   const iconArr = ref < string[] > (icons);
  //   const visible = ref < boolean > (false);
  //   const searchValue = ref("");

  //   const handleClick = (icon) => {
  //     context.emit("update:icon", icon);
  //     visible.value = false;
  //   };

  //   /**
  //    * 进行搜索过滤
  //    */
  //   const filterIcon = () => {
  //     if (searchValue.value) {
  //       iconArr.value = icons.filter((item) =>
  //         item.toLowerCase().includes(searchValue.value.toLowerCase())
  //       );
  //     } else {
  //       iconArr.value = icons;
  //     }
  //   };

  //   watch(visible, () => {
  //     searchValue.value = "";
  //     iconArr.value = icons;
  //   });

  //   return {
  //     visible,
  //     iconArr,
  //     handleClick,
  //     searchValue,
  //     filterIcon,
  //   };
  // },
};
</script>

<style scoped lang="less" >
.icon-box {
  overflow: auto;
  font-size: 20px;
  width: 250px;
  height: 230px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  justify-content: center;
}

.icon-content {
  width: 45px;
  height: 40px;
  margin: 5px;
  cursor: pointer;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #ccc;
  i {
    font-size: 18px;
  }
}

.icon-content:hover {
  color: @primaryColor;
  border-color: @primaryColor;
}
.primarycolor {
  color: @primaryColor;
  border-color: @primaryColor;
}
</style>