<template>
  <div class="page-list personnelfiles flex-1">
    <a-row class="filter-wrap  ">
      <a-col
        :lg="8"
        :md="24"
        :sm="24"
        :xs="24"
        style="margin-left:0px;"
        class="mgb-20"
      >
        <a-button
          type="primary"
          class="table-btn-clk"
          style="margin-right:19px;margin-left: 0px;"
          @click="addRules"
        >添加商品品牌</a-button>
      </a-col>
      <a-col
        :lg="16"
        :md="24"
        :sm="24"
        :xs="24"
        class="text-right flex align-item-center j-end "
      >
        <span style="margin-right:-21px;">搜索：</span>
        <a-input
          allowClear
          @search="getList"
          @pressEnter="getList"
          v-model="query.brand_name"
          placeholder="请输入品牌名称"
          class="mgl-20 mgr-20 ant-input2"
          style="width: 168px;"
        > </a-input>

        <a-button
          class="mgl-20 table-btn-clk"
          type="primary"
          style="height:32px;margin-top:1px;"
          @click="getList()"
        >查询</a-button>
      </a-col>
    </a-row>
    <a-table
      class="table-manage flex-1"
      @change="tableChanged"
      :pagination="table.pagination"
      row-key="brand_id"
      :loading="table.loading"
      :columns="config.brand.columns"
      :data-source="table.tableData"
    >

      <template
        slot="_4"
        slot-scope="action, record"
      >
        <i
          style="width:40px;height:40px;font-size:28px;"
          :class="record.brand_icon"
          class="meiye-icon"
          alt=""
        ></i>
      </template>

      <template
        slot="_7"
        slot-scope="action, record"
      >
        <a-button
          size="small"
          class="mgr-12 smallbtn"
          @click="addRules(record,3)"
        >编辑</a-button>
        <a-button
          size="small"
          class="mgr-12 smallbtn"
          @click="handleBatch('delete',[record],'提示')"
        >删除</a-button>
      </template>
    </a-table>
    <!-- 编辑添加 -->
    <a-modal
      :visible="permissionInfo.visible"
      width="750px"
      wrapClassName="commonModel"
      @cancel="permissionInfo.visible=false"
      :okText="permissionInfo.text"
      @ok="okclickmod"
      destroyOnClose
      :bodyStyle="{height: '258px'}"
    >
      <div class="constmodel">
        <a-row type="flex">

          <div class="linebox flex flex-1 align-item-center">
            <div class="lfbox"><span class="imgxing">*</span>品牌名称：</div>
            <div
              class="rgbox  "
              style="width: 258px;"
            >
              <a-input
                v-model="permissionInfo.info.brand_name"
                placeholder="请输入品牌名称"
              ></a-input>
            </div>
          </div>

        </a-row>
        <a-row
          type="flex"
          style="justify-content: space-between;"
        >
          <a-col>
            <div class="linebox flex align-item-center">
              <div class="lfbox"><span class="imgxing">*</span>品牌状态：</div>
              <div class="rgbox">
                <a-radio-group v-model="permissionInfo.info.brand_status">
                  <a-radio :value="0">
                    不展示
                  </a-radio>
                  <a-radio :value="1">
                    展示
                  </a-radio>
                </a-radio-group>
              </div>
            </div>
          </a-col>

        </a-row>
        <a-row
          type="flex"
          style="justify-content: space-between;"
        >
          <a-col>
            <div class="linebox flex align-item-center">
              <div class="lfbox"> 自定义排序</div>
              <div class="rgbox">
                <a-radio-group v-model="permissionInfo.info.brand_sort">
                  <a-radio :value="1">
                    正序
                  </a-radio>
                  <a-radio :value="2">
                    倒序
                  </a-radio>
                </a-radio-group>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row
          type="flex"
          style="justify-content: space-between;"
        >
          <a-col>
            <div class="linebox flex align-item-center">
              <div class="lfbox"> 品牌图标</div>
              <div class="rgbox">
                <icon-picker
                  v-model="permissionInfo.info.brand_icon"
                  :icon="permissionInfo.info.brand_icon"
                  :trigger="'hover'"
                  @getIconName="getIconName"
                >
                  <template #iconSelect>
                    <div class="icons">
                      <i
                        class="meiye-icon"
                        v-if="permissionInfo.info.brand_icon"
                        :class="permissionInfo.info.brand_icon"
                      ></i>
                      <a-icon
                        type="plus"
                        v-else
                      />
                    </div>

                  </template>
                </icon-picker>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <template slot="title">
        <div style="margin: 3px 0;">
          <span class="titmodel">{{ permissionInfo.title }}</span>
        </div>
      </template>
    </a-modal>
  </div>

</template>
<script> 
import storage from 'store'
import { Getbrandlist, Createbrand, Updatebrand, brandInfo } from '@/api/commodity'
import config from './config'
import axios from 'axios'
import IconPicker from '@/components/intimate/IconPicker'
export default {
  components: { IconPicker },
  data() {
    return {
      config,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        token: storage.get('token'),
        "Content-Type": 'multipart/form-data'
      },
      coptionsList: [],
      query: {
        brand_name: undefined,
        limit: 10,
        page: 1,
      },
      table: {
        tableData: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      },
      permissionInfo: {
        visible: false,
        text: '提交',
        title: '',
        type: 1,//1新增   3编辑
        pic_url_loading: false,
        isDisabled: false,
        info: {
          brand_id: undefined,
          brand_name: undefined,
          brand_icon: undefined,
          brand_status: undefined,// 
          brand_sort: 1
        }
      },
      treeData: [],
      selectreeObj: {
        children: 'children',
        title: 'brand_name',
        key: 'brand_id',
        value: 'brand_id'
      },
    }
  },
  async created() {
    // this.getCompanyoptionList()
    this.getList()

  },
  methods: {
    handleMenuClick({ key }) {
      this.handleBatch(key)
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.info.pic_url_loading = true
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        const { file_url } = info.file.response.data
        this.permissionInfo.info.permission_icon = file_url
        this.permissionInfo.info.pic_url_loading = false
      }
    },
    // 删除 
    handleBatch(key, record, title = "删除") {
      let self = this
      let deleteRules = ''
      const urlArr = [
        { name: deleteRules, key: 'delete', okType: 'primary', msg: '确定要删除吗？删除后将无法恢复，请谨慎操作！' },
      ]
      const activeObj = urlArr.filter(it => it.key == key)[0]
      self.$confirm({
        icon: 'exclamation-circle',
        closable: true,
        title,
        content: activeObj.msg,
        okText: '确定',
        closable: true,
        okType: activeObj.okType,
        cancelText: '取消',
        onOk() {
          const data = {
            employee_ids: selectedRowKeys,
          };
          (activeObj.name)(data).then(res => {
            self.getList()
          }).finally(r => {
          })
        },
        onCancel() {
        },
      });
    },
    getList() {
      let self = this
      self.table.loading = true
      const params = {
        filter: {
          brand_name: self.query.brand_name
        },
        page: self.query.page,
        limit: self.query.limit
      }

      return Getbrandlist(params).then(res => {
        console.log(res.data, "data");
        const { list = [], total = 0 } = res.data
        self.table.tableData = list
        self.table.pagination = Object.assign({}, { pageSize: this.query.limit, current: this.query.page }, {
          total: total
        })
        console.log(self.table.tableData, "///self.table.tableData");
      }).finally(r => {
        self.table.loading = false
      })
    },
    // 商品树形列表
    // getCompanyoptionList(e) {
    //   GetgoodsoptionsList().then(res => {
    //     this.treeRecursion(res.data)
    //     this.treeData = res.data
    //     console.log(this.treeData, '>>>this.coptionsList');
    //     if (!e) {
    //       // this.query.company_parent = res.data[0].company_id
    //       // this.permissionInfo.info.company_parent = res.data[0].company_id
    //       this.getList()
    //     }

    //   })
    // },

    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination
      const { field, order } = sorter
      this.query = Object.assign(this.query, { limit: Number(pageSize), page: Number(current) })
      this.getList()
    },
    // 提交按钮
    okclickmod(e) {
      let data = {
        ...this.permissionInfo.info
      }
      if (this.permissionInfo.type == 3) {
        delete data.created_at
        delete data.updated_at
        // delete data.brand_id
        Updatebrand(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        })
      } else {
        Createbrand(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
    async addRules(e, i) {
      this.permissionInfo.isDisabled = false
      this.permissionInfo.info = {
        brand_id: undefined,
        brand_name: undefined,
        brand_icon: undefined,
        brand_status: undefined,// 
        brand_sort: 1
      }
      // await this.getCompanyoptionList(true)

      this.permissionInfo.visible = true
      if (i == 3) {
        this.permissionInfo.isDisabled = true
        this.permissionInfo.title = '编辑商品种类'
        this.permissionInfo.info.brand_id = e.brand_id
        this.permissionInfo.type = 3
        this.openDatails()
        console.log(this.permissionInfo.info);
      } else {
        this.permissionInfo.title = '添加商品种类'
        this.permissionInfo.type = 1
        delete this.permissionInfo.info.brand_id
        console.log(this.permissionInfo);

      }
    },

    openDatails() {
      brandInfo({ brand_id: this.permissionInfo.info.brand_id }).then(res => {
        this.permissionInfo.info = Object.assign(this.permissionInfo.info, res.data)
      })
    },


    // 递归改变id key number
    treeRecursion(data) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el.brand_id) == 'number') {
          el.brand_id = el.brand_id.toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children);
      })
    },
    // 自定义展开关闭图标
    customExpandIcon(props) {
      console.log(props)
      // if(props.record.children.length > 0){
      if (props.expanded) {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">关闭详情</a-button></a>
      } else {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">查看详情</a-button></a>
      }
      // }else{
      //     return <span style={{marginRight:8 }}></span>
      // }
    },
    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 1
        if (isLt1M) {
          self.$message.error('上传文件大于1MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.permissionInfo.info.company_logo = res.data.url
      } else {
        this.$message.error(res.error.message)
      }


    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    // 获取icon
    getIconName(e) {
      this.permissionInfo.info.brand_icon = e
    }
  },


}
</script>
<style lang="less" scoped>
.personnelfiles {
  // padding: 12px;
  margin: 20px 12px 20px 20px;
  overflow: hidden;
  // background: #fff;
  // height: 100%;
  // min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  .filter-wrap {
    background: #fff;
    border-radius: 4px 4px 0 0 ;
  }
  /deep/ .ant-checkbox-wrapper {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}
.borderbox {
  margin-left: 4px;
  display: inline-block;
  border-radius: 6px;
  text-align: center;
  width: 20px;
  height: 18px;
  background: @btn2mianbgDColor;
  box-shadow: 0px 2px 3px 0px @btn2mianOutDColor,
    inset 0px -2px 0px 0px @btn2mianInsertDColor;
  border-radius: 4px;
  border: 1px solid @btn2mianboderDColor;
  line-height: 14px;

  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.bigdropdown_imga {
  display: none;
}
.bigdropdown_img1a {
  display: none;
}
.bigdropdown {
  position: relative;
  width: 100px;
  height: 18px;
  display: inline-block;
  line-height: 18px;
  .borderbox {
    position: absolute;
    top: 0;
    left: 29px;
    i {
      font-size: 8px;
      margin-top: -1px;
      transform: scale(0.83);
      display: inline-block;
      color: @btn2mianfontDColor;
    }
    &:hover {
      border: 1px solid @btn2mianboderHColor;
      i {
        color: @btn2mianfontHColor;
      }
    }
  }
}
/deep/ .bgchangenode {
  background: #ccc;
}
/deep/.ant-modal-header {
  padding-left: 24px;
}
/deep/.ant-modal-footer {
  padding-bottom: 32px;
}
.btn-padding {
  display: none;
  // background: rgba(216, 219, 236, 0.27);
  border-radius: 4px;
  color: @btn3mianFontDColor;
  background: #f5f5fa;
  border: none !important;
  box-shadow: none !important;
  &:hover {
    color: @btn3mianFontHColor !important;
    background-color: @btn3mianbgHColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  &:focus {
    color: @btn3mianFontFColor !important;
    background-color: @btn3mianbgFColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  // border: 1px solid @btn2mianboderDColor;
  // box-shadow: 0px 2px 4px 0px @btn2mianOutDColor, inset 0px -2px 0px 0px @btn2mianInsertDColor;
  // background-color: @btn2mianbgDColor;
  // color: @btn2mianfontDColor;
  // &:active {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor !important;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:focus {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:hover {
  //   color: @btn2mianfontHColor !important;
  //   border: 1px solid @btn2mianboderHColor !important;
  //   background-color: @btn2mianbgHColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutHColor, inset 0px -2px 0px 0px @btn2mianInsertHColor;
  // }
}
/deep/ .ant-table-header {
  margin-top: -23px;
}
/deep/ .borderbox.ant-dropdown-open {
  border-color: @baseColor35 !important;
  color: @baseColor35 !important;
  i {
    color: @baseColor35 !important;
  }
}
/deep/
  .ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-cascader-menu-item:hover,
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-tree li .ant-tree-node-content-wrapper:hover,
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: @selectdownbgHColor !important;
  // color: #0A955D;
  color: @selectdownfontHColor;
  font-weight: 400;
}
.smallbtnlong {
  letter-spacing: -1px !important;
}
/deep/ .ant-table-body {
  overflow: hidden !important;
  overflow-y: scroll !important;
}
.meiye-icon {
  color: @primaryColor;
}
/deep/ .ant-input2 .ant-input {
  padding-top: 5px;
  &::placeholder {
    padding-top: 3px;
  }
}
.linebox {
  display: flex;
  padding: 12px 0;

  .lfbox {
    width: 110px;
    font-size: 14px;
    color: @fontColor5;
    text-align: left;
    .imgxing {
      width: 8px;
      height: 22px;
      color: red;
    }
  }

  .rgbox {
    font-size: 14px;
    color: @btnColorfont1;
    // width: calc(100% - 120px);
    position: relative;

    .tuijia {
      font-size: 14px;
      color: @fontColor6;
    }
  }
}
/deep/ .ant-upload-picture-card-wrapper .ant-upload {
  width: 45px !important;
  height: 45px !important;
  padding: 0;
}
/deep/ .ant-upload-select-picture-card i {
  font-size: 14px;
}
.icons {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 18px;
  border: 1px dashed #cbcbcb;
  .icon {
    font-size: 18px;
  }
  cursor: pointer;
  &:hover {
    color: @primaryColor;
    border: 1px dashed @primaryColor;
  }
}
</style>